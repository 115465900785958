import { createReducer, on, Action } from '@ngrx/store';
import * as fromUI from '../actions';

export interface UIState {
  loading: boolean;
  sidenav_menu: boolean;
  sidenav_user: boolean;
}

const INIT_STATE: UIState = {
  loading: false,
  sidenav_menu: false,
  sidenav_user: false
};

const REDUCER = createReducer(

  INIT_STATE,
  on(fromUI.UI_LOADING, (state, { loading }) => ({
    ...state,
    loading
  })),

  on(fromUI.UI_OPEN_SIDENAV_MENU, (state, { open }) => ({
    ...state,
    sidenav_menu: open
  })),

  on(fromUI.UI_OPEN_SIDENAV_USER, (state, { open }) => ({
    ...state,
    sidenav_user: open
  })),

  on(fromUI.UI_RESET, () => (INIT_STATE))

);

export function UI_REDUCER(state: UIState | undefined, action: Action) {
  return REDUCER(state, action);
}
