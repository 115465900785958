<form [formGroup]="formulario" (submit)="search_patient()">

  <mat-form-field class="mb-4">
    <input matInput formControlName="document" placeholder="{{ 'formSearch.id' | translate }}">
    <mat-hint align="end">
      <jlps-field-errors [form]="formulario" field="document"></jlps-field-errors>
    </mat-hint>
  </mat-form-field>

  <div [class]="align_button">
    <button mat-flat-button class="rounded-pill px-5 py-1" type="submit" color="accent">{{ 'formSearch.consult' | translate }}</button>
  </div>

</form>
