import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';

// Servicios & Herramientas
import { AppService } from 'src/app/services';
import { APP_CONFIG } from 'src/environments/environment.global';

export enum EnumRoleUser {
  default = 'ROLE_REFRESH_TOKEN',
  bienestar = 'ROLE_WELLNESS',
  doctor = 'ROLE_DOCTOR',
  laboratorio = 'ROLE_LABORATORY'
}

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivateChild {

  constructor(
    private app: AppService,
    private router: Router
  ) { }

  canActivateChild(): boolean {

    const ROLES: string[] = this.app.storage.get_item(APP_CONFIG.storage_key_roles);
    const ROL_DOCTOR = ROLES.includes(EnumRoleUser.doctor) || ROLES.includes(EnumRoleUser.laboratorio);;

    if (ROL_DOCTOR) {
      return true;
    } else {
      this.router.navigate(['/diary']);
      return false;
    }
  }

}
