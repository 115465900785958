import { Component } from '@angular/core';
import { Router } from '@angular/router';

// NgRx
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducers';
import { UI_OPEN_SIDENAV_USER, MENU_PATIENT_RESET } from 'src/app/store/actions';

// Modals
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSearchPatientComponent } from 'src/app/shared/components/modals';

// Servicios
import { AuthService, AppService } from 'src/app/services';

// Herramientas
import { APP_CONFIG } from 'src/environments/environment.global';
import { EnumRoleUser } from 'src/app/services/guards/role.guard';

@Component({
  selector: 'jlps-sidebar-user',
  templateUrl: './sidebar-user.component.html',
  styles: []
})
export class SidebarUserComponent {

  constructor(
    private app: AppService,
    private router: Router,
    private modalService: NgbModal,
    private authServices: AuthService,
    private store: Store<AppState>
  ) {
    const ROLES: string[] = this.app.storage.get_item(APP_CONFIG.storage_key_roles);
    const IDX_DOCTOR_ROL = ROLES.findIndex(i => i === EnumRoleUser.doctor);

  }

  logout() {
    this.authServices.logout();
    this.store.dispatch(MENU_PATIENT_RESET());
  }

  open_modal_search_patient() {
    const MODAL = this.modalService.open(ModalSearchPatientComponent, { centered: true });
    MODAL.result
      .then(() => this.store.dispatch(UI_OPEN_SIDENAV_USER({ open: false })))
      .catch(() => null);
  }

  router_link(path: string) {
    this.router.navigateByUrl(path);
    this.store.dispatch(UI_OPEN_SIDENAV_USER({ open: false }));
  }
}
