import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

// Herramientas & servicios.
import { AppService } from '../settings/app.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private app: AppService,
    private router: Router,
  ) { }

  canActivate({queryParams}: ActivatedRouteSnapshot): boolean {

    const QUERY_TOKEN = !queryParams.pk;
    const PAYLOAD_TOKEN = this.app.token.payload();

    if (PAYLOAD_TOKEN && QUERY_TOKEN) {

      const EXP = this.app.token.expirado(PAYLOAD_TOKEN.exp);

      if (EXP) {
        this.app.storage.clear();
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }

    } else {
      this.app.storage.clear();
      return true;
    }
  }

}
