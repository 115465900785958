import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'jlps-list-item-form',
  templateUrl: './list-item-form.component.html',
  styles: []
})
export class ListItemFormComponent implements OnInit {

  @Input() disabled = true;

  @Input() min: number;
  @Input() max: number;
  @Input() value: number;

  @Input() label: string;
  @Input() info: string;

  ngOnInit() {

    if (!this.info) {

      if (this.min && this.max) {
        this.info = `${this.min} - ${this.max}`;
      }

    }
  }

}
