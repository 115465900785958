import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Herramientas
import { FormTools } from 'src/app/shared/tools';
import * as Config from 'src/environments/environment.global';

// Servicios
import { UserService, AppService } from 'src/app/services';
import { IntInfoGeneral } from 'src/app/shared/tools/interfaces';

enum TypeRol {
  laboratory = 1,
  doctor,
  bienestar
}

@Component({
  selector: 'jlps-modal-new-account',
  templateUrl: './modal-new-account.component.html',
  styles: []
})
export class ModalNewAccountComponent {

  formulario: FormGroup;
  formTools: FormTools;

  show_password = false;
  show_button_password = false;

  documentTypes: IntInfoGeneral[] = [];
  doctorType: IntInfoGeneral[] = [];
  cities: IntInfoGeneral[] = [];
  specialties: IntInfoGeneral[] = [];
  educations: IntInfoGeneral[] = [];

  constructor(
    public modal: NgbActiveModal,
    private app: AppService,
    private userService: UserService,
    private fb: FormBuilder
  ) {
    this.form_build();
    this.userService.get_settings()
    .subscribe(({ doctorType, documentTypes, cities, educations, specialties }) => {
      this.documentTypes = documentTypes;
      this.doctorType = doctorType;
      this.cities = cities;
      this.specialties = specialties;
      this.educations = educations;
    });
  }

  create_account() {

    const { invalid, value } = this.formulario;
    if (invalid) {
      this.formTools.show_all_errors_form(this.formulario);
      return;
    }

    const { specialties: specialtiesForm } = value;
    const specialties = specialtiesForm && specialtiesForm.id ? specialtiesForm.id.map((i: number) => ({ id: i })) : [];

    const payload = { ...value, specialties };

    this.app.loading(true);
    this.userService.save_doctor(payload)
      .subscribe(() => {
        this.app.loading(false);
        this.modal.close();
      });
  }

  getConfiguration() {
    this.userService.get_settings()
      .subscribe(({ doctorType, documentTypes, educations, specialties }) => {
        this.documentTypes = documentTypes;
        this.doctorType = doctorType;
        this.specialties = specialties;
        this.educations = educations;

        this.formulario.controls.name.enable();
        this.formulario.controls.lastName.enable();
        this.formulario.controls.documentType.enable();
        this.formulario.controls.documentNumber.enable();
        this.formulario.controls.state.enable();
        this.formulario.controls.phoneNumber.enable();
        this.formulario.controls.professionalCardNumber.enable();
        this.formulario.controls.type.enable();
        this.formulario.controls.education.enable();
        this.formulario.controls.specialties.enable();
        this.formulario.controls.email.enable();
        this.formulario.controls.password.enable();
      });

  }

  private form_build() {

    this.formulario = this.fb.group({
      name: [null, Validators.required],
      lastName: [null, Validators.required],
      documentType: this.fb.group({ id: [null, Validators.required] }),
      documentNumber: [null, [Validators.required, Validators.minLength(5)]],
      city: this.fb.group({ id: [null, Validators.required] }),
      phoneNumber: [null, Validators.required],
      type: this.fb.group({ id: [null, Validators.required] }),
      professionalCardNumber: [null, [Validators.required, Validators.minLength(4)]],
      specialties: this.fb.group({ id: [null, Validators.required] }),
      education: this.fb.group({ id: [null, Validators.required] }),
      email: [null, [Validators.required, Validators.pattern(Config.RGX.email)]],
      password: [null, [Validators.required, Validators.minLength(5)]]
    });

    this.formTools = new FormTools(this.formulario);

    const CONTROL = this.formulario.controls;
    const ROL_CONTROL: any = CONTROL.type;

    CONTROL.specialties.disable();

    CONTROL.password.valueChanges.subscribe(() => {
      this.show_button_password = !this.formTools.show_has_errors('password');
    });

    ROL_CONTROL.controls.id.valueChanges.subscribe(value => {
      if (value !== TypeRol.laboratory) {
        CONTROL.specialties.enable();
      } else {
        CONTROL.specialties.disable();
      }
    });
  }

}
