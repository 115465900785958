import { Injectable } from '@angular/core';

// Herramientas
import { APP_CONFIG } from 'src/environments/environment.global';
import { EncryptTools } from 'src/app/shared/tools';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private encryptTools: EncryptTools = new EncryptTools();

  set_item(payload: any, key: string) {
    APP_CONFIG.storage_type.setItem(key, this.encryptTools.encrypt(payload));
  }

  get_item(key: string): any {
    const PAYLOAD_ENCRYPT = APP_CONFIG.storage_type.getItem(key);
    if (!PAYLOAD_ENCRYPT) { return; }
    return this.encryptTools.desencrypt(PAYLOAD_ENCRYPT);
  }

  remove_item(key: string): any {
    APP_CONFIG.storage_type.removeItem(key);
  }

  clear() {
    APP_CONFIG.storage_type.clear();
  }
}
