import { Injectable } from '@angular/core';

// Herramientas
import * as moment from 'moment';
import { APP_CONFIG } from 'src/environments/environment.global';

// Servicios
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private storage: StorageService) { }

  save(payload: IntToken) {
    this.storage.set_item(payload.token, APP_CONFIG.storage_key_token);
    this.storage.set_item(payload.refresh_token, APP_CONFIG.storage_key_token_refresh);
  }

  get(): IntToken {
    return {
      token: this.storage.get_item(APP_CONFIG.storage_key_token),
      refresh_token: this.storage.get_item(APP_CONFIG.storage_key_token_refresh)
    };
  }

  payload(): IntTokenPayload {

    const TOKEN = this.get().token;

    if (TOKEN) {
      const PAYLOAD_STRING = decodeURIComponent(escape(atob(TOKEN.split('.')[1])));
      const PAYLOAD_JSON = JSON.parse(PAYLOAD_STRING);

      return PAYLOAD_JSON;
    }
  }

  expirado(date_exp: number): boolean {
    const NOW = moment().unix();
    return date_exp < NOW;
  }
}

//#region Interfaces

interface IntToken {
  token: string;
  refresh_token: string;
}

interface IntTokenPayload {
  sub: string;
  scopes: string[];
  iss: string;
  iat: number;
  exp: number;
}

//#endregion
