import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'img'
})
export class ImgPipe implements PipeTransform {

  transform(img: string, type: 'user' | 'img' = 'user'): string {
    return img ? img : `assets/images/default_${type}.svg`;
  }

}
