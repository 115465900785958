import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { concat } from 'rxjs';

// Servicios
import { AppService } from 'src/app/services/settings/app.service';

// NgRx
import { Store } from '@ngrx/store';
import { AppState } from '../store/patient.reducer';
import { APP_CONFIG, DATE_FORMAT } from 'src/environments/environment.global';

import Swal from 'sweetalert2';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HealthService {

  id_medical_history: number;

  constructor(
    private app: AppService,
    private router: Router,
    private store: Store<AppState>
  ) {

    this.store.select(state => state.forms ? state.forms.medical_history_id : null)
      .subscribe(value => this.id_medical_history = value);
  }

  get_medical_history(id: number) {
    return this.app.get<{ medicalCheckList: IntMedicalHistory[] }>('/health', { params: { userId: id } });
  }

  save(id_patient: number) {

    const RELATED_APPOINTMENT = this.app.storage.get_item(APP_CONFIG.storage_key_related_appointment) || 0;
    const SPECIALTY_NAME = this.app.storage.get_item(APP_CONFIG.storage_key_specialty);
    const DOCTOR_ID = this.app.storage.get_item(APP_CONFIG.storage_key_user_id);
    const DATE = this.app.storage.get_item(APP_CONFIG.storage_key_medial_date) || moment().format('YYYY-MM-DD');
    const TIME = this.app.storage.get_item(APP_CONFIG.storage_key_medial_time) || moment().format('HH:mm');

    const PAYLOAD = {
      user: { id: id_patient },
      specialtyName: SPECIALTY_NAME,
      doctor: { id: DOCTOR_ID },
      medicalCheckDate: DATE,
      medicalCheckTime: TIME,
      relatedAppointment: RELATED_APPOINTMENT
    };

    return this.app.save('/health', PAYLOAD);
  }

  async deleted() {

    const ID_MEDICAL_HISTORY = this.app.storage.get_item(APP_CONFIG.storage_key_medial_history_id);
    const ID_PATIENT = this.app.storage.get_item(APP_CONFIG.storage_key_patient_id);

    const CONFIRM = await Swal.fire({
      title: this.app.translate.getByKey('medicalCheck.delete.title'),
      text: this.app.translate.getByKey('medicalCheck.delete.text'),
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d32f2f',
      confirmButtonText: this.app.translate.getByKey('medicalCheck.delete.confirmation')
    });

    if (CONFIRM.value) {
      concat(this.app.delete(`/health/${ID_MEDICAL_HISTORY}`), this.get_medical_history(ID_PATIENT))
        .subscribe((resp: any) => {

          if (!!resp && !!resp.medicalCheckList) {
            this.app.storage.remove_item(APP_CONFIG.storage_key_medial_history_new);
            this.app.storage.remove_item(APP_CONFIG.storage_key_specialty);
            this.app.storage.remove_item(APP_CONFIG.storage_key_related_appointment);

            const ROUTES = ['diary'];
            this.router.navigate(ROUTES);
          }
        });
    }
  }
}

//#region Interfaces

export interface IntMedicalHistory {
  id: number;
  medicalCheckDate: string;
  medicalCheckTime: string;
  specialtyName: string;
  doctor?: any;
  relatedAppointment: number;
}

//#endregion
