import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

// Configuraciones y Herramientas
import { FormTools } from 'src/app/shared/tools';
import * as Config from 'src/environments/environment.global';

// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalNewAccountComponent } from '../../components/modals';

// Servicios
import { AppService, AuthService } from 'src/app/services';

@Component({
  selector: 'jlps-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {

  formulario: FormGroup;
  formTools: FormTools;

  show_password = false;
  show_button_password = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private modal: NgbModal,
    private authServices: AuthService,
    private fb: FormBuilder,
    private app: AppService
  ) {
    this.app.translate.getObservableByKey('login.title').subscribe((value: string) => {
      this.title.setTitle(`${value} | ${Config.PROJECT_NAME}`)
    });
   
    this.activatedRoute.queryParams.subscribe(resp => this.authServices.shared_token(resp));
    this.form_build();
  }

  iniciar_sesion() {
    if (this.formulario.invalid) {
      this.formTools.show_all_errors_form(this.formulario);
      return;
    }

    this.authServices.login(this.formulario.value);
  }

  open_modal() {
    this.modal.open(ModalNewAccountComponent, {
      centered: true,
      size: 'lg',
      backdropClass: 'bg-login-backdrop',
      windowClass: 'maik-modal-new-account'
    });
  }

  private form_build() {

    this.formulario = this.fb.group({
      username: [null, [Validators.required, Validators.pattern(Config.RGX.email)]],
      password: [null, [Validators.required, Validators.minLength(6)]],
    });

    this.formTools = new FormTools(this.formulario);

    const CONTROL = this.formulario.controls;
    CONTROL.password.valueChanges.subscribe(() => {
      this.show_button_password = !this.formTools.show_has_errors('password');
    });
  }

}
