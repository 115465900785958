import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class Translations {

    constructor(private translate: TranslateService) {}

    getByKey(key: string, params?: Object): string {
        return this.translate.instant(key, params);
    }

    getObservableByKey(key: string): Observable<string> {
        return this.translate.get(key);
    }

    getBrowserLang(): string {
        var locale = this.translate.getBrowserLang()
        return locale.match(/es/) ? locale : 'en'
    }

    setDefaultLocale(locale: string) {
        this.translate.setDefaultLang(locale)
    }
}
