import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

// Servicios
import { AuthService } from '../../auth.service';
import { TokenService } from './token.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private token: TokenService,
    private translate: TranslateService,
    private app: AppService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const TOKEN = this.token.get().token;
    const AUTHORIZATION = request.headers.get('Authorization');
    const BEARER_TOKEN = !!TOKEN && !AUTHORIZATION ? 'Bearer ' + TOKEN : AUTHORIZATION;

    const browserLang = this.translate.getBrowserLang();
    var locale = browserLang.match(/en/) ? browserLang : 'es'

    const contentType = this.app.getContentType(request.headers.get('Content-Type'))

    request = request.clone({
      setHeaders: {
        'Authorization': BEARER_TOKEN || '',
        'Content-Type': contentType,
        'lang': locale
      }
    });

    document.body.style.cursor = 'progress';
    const SLOW_CONNECTION = setTimeout(() => document.body.style.cursor = 'wait', 2000);

    return next.handle(request).pipe(
      catchError(async error => {

        const _AUTHORIZATION = request.headers.get('Authorization');
        const _SHARED_TOKEN = request.url.match('share-token');

        if (error.status === 401 && !!_AUTHORIZATION && !_SHARED_TOKEN) {
          const resp = await this.authService.refresh_token().toPromise();

          if (resp) {
            request = request.clone({
              headers: request.headers
                .set('Authorization', 'Bearer ' + resp.token)
            });
          } else {
            this.authService.logout();
          }
        }

        throw error;
      }),
      catchError(() => next.handle(request)),
      finalize(() => {
        document.body.style.cursor = 'auto';
        clearTimeout(SLOW_CONNECTION);
      })
    );
  }
}
