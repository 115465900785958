import { AbstractControl, FormGroup, FormControl, FormArray } from '@angular/forms';

export class FormTools {

  constructor(public form: FormGroup) { }

  get_control(field: string | AbstractControl): AbstractControl {
    const CONTROL = field instanceof AbstractControl ? field : this.form.get(field);
    return CONTROL;
  }

  valido(control: AbstractControl): boolean {
    return (control.touched || control.dirty);
  }

  show_has_errors(field: string): boolean {
    const CONTROL = this.get_control(field);
    return CONTROL && CONTROL.invalid && this.valido(CONTROL);
  }

  get_errors(field: string | AbstractControl): any {
    const CONTROL = this.get_control(field);
    return CONTROL && CONTROL.errors;
  }

  get_name_error(field: string | AbstractControl): string {
    const ERRORS = this.get_errors(field);
    const ERROR = Object.keys(ERRORS ? ERRORS : {})[0];
    return ERROR;
  }

  get_error(field: string): any {
    const ERRORS = this.get_errors(field);
    const ERROR = Object.keys(ERRORS)[0];
    return ERRORS[ERROR];
  }

  show_all_errors_form(form: FormGroup | FormArray) {
    Object.keys(form.controls).forEach(field => {
      const CONTROL = form.get(field);
      if (CONTROL instanceof FormControl) {
        CONTROL.markAsTouched({ onlySelf: true });
      } else if (CONTROL instanceof FormGroup) {
        this.show_all_errors_form(CONTROL);
      } else if (CONTROL instanceof FormArray) {
        CONTROL.markAsTouched({ onlySelf: true });
        this.show_all_errors_form(CONTROL);
      }
    });
  }

  reset_form_errors(form: FormGroup) {
    let control: AbstractControl = null;
    form.reset();
    form.markAsUntouched();
    Object.keys(form.controls).forEach((name) => {
      control = form.controls[name];
      control.setErrors(null);
    });
  }

}
