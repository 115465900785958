import { Component } from '@angular/core';

// Modal
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'jlps-modal-search-patient',
  templateUrl: './modal-search-patient.component.html',
  styles: []
})
export class ModalSearchPatientComponent {

  constructor(public modal: NgbActiveModal) { }

}
