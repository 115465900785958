import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Servicios
import { HttpService } from './http.service';
import { MessageService } from './message.service';
import { StorageService } from './storage.service';
import { TokenService } from './token/token.service';

// Herramientas
import { EncryptTools } from 'src/app/shared/tools';

// NgRx
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducers';
import { Translations } from 'src/app/shared/tools/translations';

@Injectable({
  providedIn: 'root'
})
export class AppService extends HttpService {

  encryptTools: EncryptTools = new EncryptTools();

  constructor(
    http: HttpClient,
    message: MessageService,
    store: Store<AppState>,
    public token: TokenService,
    public storage: StorageService,
    public translate: Translations
  ) {
    super(http, message, store, translate);
  }

  getContentType(type: string): string {
    if(type == null) {
      return 'application/json'
    }else if("PDF" == type) {
      return 'application/pdf'
    }
    return "video/mp4"
  }
}
