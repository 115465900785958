import { Pipe, PipeTransform } from '@angular/core';

// Herramientas
import * as moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentFormatPipe implements PipeTransform {

  transform(date: string | Date | moment.Moment, out_format: string, in_format?: string): string {
    const DATE_STRING = moment(date, in_format).locale('es').format(out_format);
    return DATE_STRING.toUpperCase();
  }

}
