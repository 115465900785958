import { Injectable } from '@angular/core';
import { CanLoad, CanActivate } from '@angular/router';

// Servicios
import { AppService, AuthService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {

  constructor(
    private app: AppService,
    private authServices: AuthService
  ) { }

  canLoad = (): boolean => this.permitir_acceso();
  canActivate = (): boolean => this.permitir_acceso();

  private permitir_acceso(): boolean {

    const PAYLOAD_TOKEN = this.app.token.payload();

    if (PAYLOAD_TOKEN) {
      return true;
    } else {
      this.authServices.logout();
      return false;
    }
  }

}
