import { Component } from '@angular/core';

// Herramientas y Modelos
import { IntItemMenu } from 'src/app/shared/settings/menu.settings';

// NgRx
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/patient.reducer';

@Component({
  selector: 'jlps-sidebar',
  templateUrl: './sidebar.component.html',
  styles: [`
    .maik-btn-new-roport {
      position: absolute !important;
      bottom: 0 !important;
      left: 0 !important;
    }
  `]
})
export class SidebarComponent {

  menu: IntItemMenu[];
btn_show: boolean;

  constructor(
    private store: Store<AppState>
  ) {
    this.store.select('menu').subscribe(menu => {
      this.menu = menu ? menu.items : [];
this.btn_show = menu ? menu.button : false;
    });
  }

}
