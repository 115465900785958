import { createAction, props } from '@ngrx/store';

// Herramientas
import { EnIdMenu } from 'src/app/shared/tools/enums';

export const MENU_PATIENT_DISABLED = createAction('[MENU PACIENTE] :: Deshabilitar todo el menu.');
export const MENU_PATIENT_ENABLED = createAction('[MENU PACIENTE] :: Habilitar todo el menu.');
export const MENU_PATIENT_ID = createAction('[MENU PACIENTE] :: Habilitar/Deshabilitar menu por ID.', props<{ id: EnIdMenu, disabled: boolean }>());
export const MENU_PATIENT_BTN_NEW_REPORT = createAction('[MENU PACIENTE] :: Mostrar/Ocultar boton de crear nuevo reporte.', props<{ show: boolean }>());

export const MENU_PATIENT_RESET = createAction('[MENU PACIENTE] :: Reset.');

