import { Component } from '@angular/core';

// NgRx
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducers';
import { UI_OPEN_SIDENAV_USER } from 'src/app/store/actions';

// Servicios y herramientas
import { AppService } from 'src/app/services';
import { APP_CONFIG } from 'src/environments/environment.global';

@Component({
  selector: 'jlps-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  username: string;
  openSidenav: boolean;

  constructor(
    private app: AppService,
    private store: Store<AppState>
  ) {

    this.username = this.app.storage.get_item(APP_CONFIG.storage_key_user);
    this.store.select(state => state.ui.sidenav_user).subscribe(open => this.openSidenav = open);
  }

  toggle_sidebar() {
    this.store.dispatch(UI_OPEN_SIDENAV_USER({ open: !this.openSidenav }));
  }

}
