import { Component, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, NavigationError } from '@angular/router';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

// NgRx
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducers';

// Servicios y Herramientas
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../services';
import Swal from 'sweetalert2';

@Component({
  selector: 'jlps-shell',
  template: `
    <div class="loading" *ngIf="loading$ | async">
      <span class="spinner-grow text-primary" role="status"></span>
    </div>
    <div *ngIf="!online" class="p-4 bg-danger text-white d-flex justify-content-center fixed-bottom fadeIn">
    	<div class="container-fluid">
    		<p class="mb-0">
    			<mat-icon class="mr-2">wifi_off</mat-icon>
    			<b>No hay conexión a Internet</b>, los componentes no funcionarán correctamente.
    		</p>
    	</div>
    </div>
    <router-outlet class="fadeIn"></router-outlet>`,
})
export class ShellComponent implements AfterViewInit {

  online: boolean = navigator.onLine;
  loading$: Observable<boolean>;

  constructor(
    public app: AppService,
    private router: Router,
    private modalConfig: NgbModalConfig,
    private store: Store<AppState>
  ) {

    this.modalConfig.backdrop = 'static';
    this.modalConfig.keyboard = false;

    window.addEventListener('online', () => this.online = true);
    window.addEventListener('offline', () => this.online = false);

    this.loading$ = this.store.select(state => state.ui.loading).pipe(delay(0));
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.app.loading(true);
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.app.loading(false);
      } else if (event instanceof NavigationError) {
        this.app.loading(false);
        if(event.error.status > 499) {
          Swal.fire({
            title: this.app.translate.getByKey('shellComponent.title'), 
            text: this.app.translate.getByKey('shellComponent.text'),
            type: 'error',
            allowEscapeKey: false,
            allowOutsideClick: false
          });
        }
      }
    });
  }

}
