import { Component } from '@angular/core';

// NgRx
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducers';
import { UI_OPEN_SIDENAV_USER } from 'src/app/store/actions';

@Component({
  selector: 'jlps-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent {

  opened_user: boolean;
  opened_menu: boolean;

  constructor(
    private store: Store<AppState>
  ) {
    this.store.select('ui').subscribe(ui => {
      this.opened_user = ui.sidenav_user;
      this.opened_menu = ui.sidenav_menu;
    });
  }

  close_sidenav() {
    this.store.dispatch(UI_OPEN_SIDENAV_USER({ open: false }));
  }

}
