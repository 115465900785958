import { Component, Input } from '@angular/core';

// Herramientas
import { tpIconForm, tpIconColor } from 'src/app/shared/tools/types';

@Component({
  selector: 'jlps-icon',
  templateUrl: './icon-list-item-form.component.html',
  styles: []
})
export class IconListItemFormComponent {

  @Input() disabled = true;
  @Input() min: number;
  @Input() max: number;

  icon: tpIconForm = 'check_circle';
  color: tpIconColor = 'text-success';

  @Input() set value(value: number) {

    if (value) {

      if (value < this.min || value > this.max) {

        this.icon = 'info';
        this.color = 'text-danger';
      } else {

        this.icon = 'check_circle';
        this.color = 'text-success';
      }
    } else {

      this.icon = 'check_circle';
      this.color = 'text-success';
    }

  }

}
