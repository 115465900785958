import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Translations } from 'src/app/shared/tools/translations';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private toastr: ToastrService, 
    private translate: Translations) { }

  success(message: string, params?: Object) {
    let infoMessage = this.translate.getByKey('app.info', params)
    this.toastr.success(message, infoMessage);
  }

  info(message: string, params?: Object) {
    let infoMessage = this.translate.getByKey('app.info', params)
    this.toastr.info(message, infoMessage);
  }

  error(message: string, params?: Object) {
    let infoMessage = this.translate.getByKey('app.error', params)
    this.toastr.error(message, infoMessage);
  }

  warning(message: string, params?: Object) {
    let infoMessage = this.translate.getByKey('app.warning', params)
    this.toastr.warning(message, infoMessage);
  }
}
