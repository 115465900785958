<form [formGroup]="formulario" (submit)="create_account()">
  <div class="modal-body p-lg-5">
    <h3 class="text-primary">{{ 'newAccount.title' | translate }}</h3>
    <p>{{ 'newAccount.description' | translate }}</p>

    <div class="row mt-5">
      <div class="col-lg-6">
        <mat-form-field>
          <input matInput autocomplete="off" formControlName="name" placeholder="{{ 'newAccount.names' | translate }}">
          <mat-hint align="end">
            <jlps-field-errors [form]="formulario" field="name"></jlps-field-errors>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field>
          <input matInput autocomplete="off" formControlName="lastName" placeholder="{{ 'newAccount.surNames' | translate }}">
          <mat-hint align="end">
            <jlps-field-errors [form]="formulario" field="lastName"></jlps-field-errors>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6" formGroupName="documentType">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'newAccount.idType' | translate }}</mat-label>
          <mat-select placeholder="{{ 'newAccount.optionWitch' | translate }}" formControlName="id">
            <mat-option *ngFor="let i of documentTypes" [value]="i.value">{{ i.name }}</mat-option>
          </mat-select>
          <mat-hint align="end">
            <jlps-field-errors [form]="formulario" field="documentType.id"></jlps-field-errors>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field>
          <input matInput type="tel" currencyMask autocomplete="off" formControlName="documentNumber" placeholder="{{ 'newAccount.identificationNumber' | translate }}">
          <mat-hint align="end">
            <jlps-field-errors [form]="formulario" field="documentNumber"></jlps-field-errors>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <mat-form-field appearance="outline" formGroupName="city">
          <mat-label>{{ 'newAccount.cityOfResidence' | translate }}</mat-label>
          <mat-select placeholder="{{ 'newAccount.optionWitch' | translate }}" formControlName="id">
            <mat-option *ngFor="let i of cities" [value]="i.value">{{ i.name }}</mat-option>
          </mat-select>
          <mat-hint align="end">
            <jlps-field-errors [form]="formulario" field="city.id"></jlps-field-errors>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
       <div class="col-lg-6">
        <mat-form-field>
          <input matInput type='tel' mask="(000) 000-0000" autocomplete="off" formControlName="phoneNumber" placeholder="{{ 'newAccount.phoneNumber' | translate }}">
          <mat-hint align="end">
            <jlps-field-errors [form]="formulario" field="phoneNumber"></jlps-field-errors>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field>
          <input matInput autocomplete="off" formControlName="professionalCardNumber" placeholder="{{ 'newAccount.professionalCardNumber' | translate }}">
          <mat-hint align="end">
            <jlps-field-errors [form]="formulario" field="professionalCardNumber"></jlps-field-errors>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <mat-form-field appearance="outline" formGroupName='type'>
          <mat-label>{{ 'newAccount.role' | translate }}</mat-label>
          <mat-select placeholder="{{ 'newAccount.optionWitch' | translate }}" formControlName="id">
            <mat-option *ngFor="let i of doctorType" [value]="i.value">{{ i.name }}</mat-option>
          </mat-select>
          <mat-hint align="end">
            <jlps-field-errors [form]="formulario" field="type.id"></jlps-field-errors>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-6" formGroupName="education">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'newAccount.specialty' | translate }}</mat-label>
          <mat-select placeholder="{{ 'newAccount.optionWitch' | translate }}" formControlName="id">
            <mat-option *ngFor="let i of educations" [value]="i.value">{{ i.name }}</mat-option>
          </mat-select>
          <mat-hint align="end">
            <jlps-field-errors [form]="formulario" field="education.id"></jlps-field-errors>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 fadeIn" *ngIf="formTools.get_control('specialties').enabled fadeIn" formGroupName="specialties">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'newAccount.optionConsult' | translate }}</mat-label>
          <mat-select placeholder="{{ 'formUser.option' | translate }}" multiple formControlName="id">
            <mat-option *ngFor="let i of specialties" [value]="i.value">{{ i.name }}</mat-option>
          </mat-select>
          <mat-hint align="end">
            <jlps-field-errors [form]="formulario" field="specialties.id"></jlps-field-errors>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <mat-form-field>
          <input matInput type="email" email autocomplete="off" formControlName="email" placeholder="{{ 'newAccount.email' | translate }}">
          <mat-hint align="end">
            <jlps-field-errors [form]="formulario" field="email"></jlps-field-errors>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field>
          <input matInput [type]="show_password ? 'text' : 'password'" formControlName="password" placeholder="{{ 'newAccount.password' | translate }}">
          <button *ngIf="show_button_password" matTooltip="Mostrar / Ocultar" mat-icon-button type="button" matSuffix (click)="show_password = !show_password">
            <mat-icon class="text-muted">{{show_password ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-hint align="end">
            <jlps-field-errors [form]="formulario" field="password"></jlps-field-errors>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="modal-footer border-top-0 px-lg-5 pb-lg-5">
    <button mat-stroked-button class="rounded-pill border-primary px-4 py-1" (click)="modal.close()" type="button" color="primary">{{ 'newAccount.closeWithoutSaving' | translate }}</button>
    <button ngbAutofocus mat-flat-button class="rounded-pill px-4 py-1" type="submit" color="primary">{{ 'newAccount.save' | translate }}</button>
  </div>
</form>
