import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';

// Herramientas
import { PROJECT_NAME } from 'src/environments/environment.global';

@Injectable({
  providedIn: 'root'
})
export class TitleGuard implements CanActivateChild {

  constructor(private title: Title) { }

  canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {

    const DATA: any = route.data;
    const TITLE = DATA.title || 'Página no encontrada - ERROR 404';
    this.title.setTitle(`${TITLE} | ${PROJECT_NAME}`);
    return true;
  }

}
