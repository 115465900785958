export const PROJECT_NAME = 'Maika';
export const VERSION = '2.0.0.';
export const BROWSERS = 'Navegadores Web recomendados: Chrome 43 o superior, Firefox 38 o superior, Safari 8 o superior.';
export const AUTOR = {
  name: 'GraciaLab',
  web: 'http://gracialab.co/'
};

export const APP_CONFIG = {
  storage_type: localStorage,
  storage_key_token: 'dG9rZW4=',
  storage_key_token_refresh: 'cmVmcmVzaHRva2Vu',
  storage_key_user: 'dXN1YXJpbw==',
  storage_key_user_id: 'aWRVc2Vy',
  storage_key_patient_id: 'aWQgcGFjaWVudGU==',
  storage_key_patient_gender: 'U2V4byBkZWwgcGFjaWVudGU=',
  storage_key_medial_history_id: 'bWVkaWNhbF9oaXN0b3J5',
  storage_key_medial_history_new: 'bnVldm8gZm9ybQ==',
  storage_key_medial_history_doctor: 'RG9jdG9yIEhpc3RvcmlhIE1lZGljYQ==',
  storage_key_medial_history_can_edit: 'UHVlZGUgZWRpdGFyIGhpc3RvcmlhIG1lZGljYQ==',
  storage_key_specialty: 'c3BlY2lhbHR5',
  storage_key_medial_date: 'bWVkaWNhbCBjaGVjayBkYXRl',
  storage_key_medial_time: 'bWVkaWNhbCBjaGVjayB0aW1l',
  storage_key_related_appointment: 'cmVsYXRlZF9hcHBvaW50bWVudA==',
  storage_key_date_id: 'aWQgY2l0YQ==',
  storage_key_roles: 'cm9sIGRlIHVzdWFyaW8=',
  storage_key_user_lang: 'dXNlciBsYW5ndWFnZQ=='
};

export const RGX = {
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  url: /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/,
  username: /^[A-Za-z0-9._]+$/
};

export const TOASTR_CONFIG = {
  progressBar: true,
  closeButton: true,
  timeOut: 10000,
  positionClass: 'toast-bottom-right',
  toastClass: 'ngx-toastr shadow',
  preventDuplicates: true
};

export const MASK_CONFIG = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 0,
  prefix: '',
  suffix: '',
  thousands: '.',
  nullable: false
};

//#region Configuracion del Paginador

import { MatPaginatorIntl } from '@angular/material';
const ESP_RANGE_LABEL = (page: number, pageSize: number, length: number): string => {
  if (length === 0 || pageSize === 0) { return `0 de ${length}`; }

  length = Math.max(length, 0);

  const START_INDEX = page * pageSize;
  const END_INDEX = START_INDEX < length ? Math.min(START_INDEX + pageSize, length) : START_INDEX + pageSize;

  return `${START_INDEX + 1} - ${END_INDEX} de ${length}`;
};

export function paginator_esp() {
  const PAGINATOR_INTL = new MatPaginatorIntl();

  PAGINATOR_INTL.itemsPerPageLabel = 'Items por página:';
  PAGINATOR_INTL.nextPageLabel = 'Siguiente';
  PAGINATOR_INTL.previousPageLabel = 'Anterior';
  PAGINATOR_INTL.getRangeLabel = ESP_RANGE_LABEL;

  return PAGINATOR_INTL;
}

//#endregion
//#region Configuración del DatePicker

import * as moment from 'moment';

/*moment.updateLocale('en', {
  months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
}); */

export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD-MM-YYYY'
  },

  
  display: {
    dateInput: 'MMMM DD [de] YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
//#endregion
