import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// Herramientas
import { FormTools, CUSTOM_VALIDATORS } from 'src/app/shared/tools';

// Servicios
import { UserService, AppService, IntUser, HealthService } from 'src/app/services';
import { APP_CONFIG } from 'src/environments/environment.global';

// NgRx
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducers';
import { FORM_RESET, MENU_PATIENT_BTN_NEW_REPORT, FORM_MEDICAL_HISTORY, FORM_MEDICAL_HISTORY_ID } from 'src/app/store/actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'jlps-form-search-patient',
  templateUrl: './form-search-patient.component.html',
  styles: []
})
export class FormSearchPatientComponent {

  @Input() align_button = 'text-right';
  @Output() response: EventEmitter<IntUser> = new EventEmitter(false);

  formulario: FormGroup;
  formTools: FormTools;

  constructor(
    private fb: FormBuilder,
    private app: AppService,
    private router: Router,
    private userServices: UserService,
    private healthServices: HealthService,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {
    this.form_build();
  }

  search_patient() {
    if (this.formulario.invalid) {
      this.formTools.show_all_errors_form(this.formulario);
      return;
    }

    const DATA = this.formulario.value;
    this.userServices.get_by_document(DATA)
      .subscribe(async res => {

        this.response.emit(res);

        const ID_ENCRYPT = this.app.encryptTools.encrypt(res.id);

        this.store.dispatch(FORM_RESET());
        this.store.dispatch(MENU_PATIENT_BTN_NEW_REPORT({ show: false }));
        this.store.dispatch(FORM_MEDICAL_HISTORY({ show: true }));

        this.app.storage.remove_item(APP_CONFIG.storage_key_specialty);
        this.app.storage.remove_item(APP_CONFIG.storage_key_date_id);
        this.app.storage.remove_item(APP_CONFIG.storage_key_medial_history_id);
        this.app.storage.remove_item(APP_CONFIG.storage_key_related_appointment);

        const RESP_HISTORY = await this.healthServices.get_medical_history(res.id).toPromise();
        const HISTORY = RESP_HISTORY.medicalCheckList;
        const ID_HISTORY = HISTORY.length && HISTORY[0].id || 0;

        this.store.dispatch(FORM_MEDICAL_HISTORY_ID({ id: ID_HISTORY }));
        this.app.storage.set_item(ID_HISTORY, APP_CONFIG.storage_key_date_id);

        this.router.navigate(['patient', ID_ENCRYPT, 'profile']);
      });
  }

  private form_build() {

    this.formulario = this.fb.group({
      document: [null, {
        validators: [Validators.required, Validators.minLength(6)],
        asyncValidators: CUSTOM_VALIDATORS.document_patient(this.userServices, this.translate),
        updateOn: 'submit'
      }]
    });

    this.formTools = new FormTools(this.formulario);
  }

}
