import { AbstractControl, ValidatorFn, AsyncValidatorFn, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

// Servicios
import { UserService } from 'src/app/services';

export const CUSTOM_VALIDATORS = {
  iguales(init_control: AbstractControl, translate: TranslateService): ValidatorFn {
    return (control: AbstractControl): IntResponseValidators | null => {

      const IGUALES = init_control.value === control.value;

      var message = ""
          translate.get('sidebarUser.fieldsAreNotEquals', { id: control.value }).subscribe((text:string) => {message = text});
          
      return IGUALES ?
        null :
        {
          iguales: {
            success: false,
            message:  message
          }
        };
    };
  },
  document_patient(services: UserService, translate: TranslateService): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<IntResponseValidators | null> => {

      return services.get_by_document({ document: control.value }).toPromise()
        .then(() => null)
        .catch(() => {

          var message = ""
          translate.get('sidebarUser.userNotFound', { id: control.value }).subscribe((text:string) => {message = text});
          
          return {
            document_not_exist: {
              document_number: control.value,
              message: message
            }
          };
        });
    };
  }
};

//#region Interfaces

interface IntResponseValidators {
  [key: string]: any;
}

//#endregion
