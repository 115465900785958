
    <mat-error class="mr-2" *ngIf="formTools.show_has_errors(field)" [ngSwitch]="get_error(field)">
    	<span *ngSwitchCase="'required'">{{ 'fieldErrors.obligatoryFiel' | translate }}</span>
    	<span *ngSwitchCase="'min'">{{ 'fieldErrors.biggerNumber' | translate }} {{ get_error_info(field).min }}.</span>
    	<span *ngSwitchCase="'max'">{{ 'fieldErrors.minorNumber' | translate }} {{ get_error_info(field).max }}.</span>
    	<span *ngSwitchCase="'pattern'">{{ 'fieldErrors.validPatern' | translate }}</span>
    	<span *ngSwitchCase="'minlength'">{{ 'fieldErrors.minimum' | translate }} {{ get_error_info(field).requiredLength }} {{ 'fieldErrors.characters' | translate }}</span>
    	<span *ngSwitchCase="'maxlength'">{{ 'fieldErrors.maximum' | translate }} {{ get_error_info(field).requiredLength }} {{ 'fieldErrors.characters' | translate }}</span>
    	<span *ngSwitchCase="'matDatepickerParse'">'{{ get_error_info(field).text }}' {{ 'fieldErrors.date' | translate }}</span>
    	<span *ngSwitchCase="'matDatepickerMax'">{{ 'fieldErrors.minorDate' | translate }} '{{ get_error_info(field).max | date:'dd-MM-yyyy' }}'.</span>
    	<span *ngSwitchCase="'matDatepickerMin'">{{ 'fieldErrors.olderDate' | translate }} '{{ get_error_info(field).min | date:'dd-MM-yyyy' }}'.</span>
    	<span *ngSwitchDefault>{{ get_error_info(field).message }}</span>
    </mat-error>