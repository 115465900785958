<div class="fadeIn d-flex justify-content-center align-items-center bg-login py-5">
  <div class="mx-4">
    <h1 class="text-white text-center fadeInBottom mb-5">
      <small class="font-weight-light">{{ 'app.hello' | translate }}</small><br>
      <span class="display-2 font-weight-normal">{{ 'app.name' | translate }}</span>
    </h1>
    <div class="card rounded-0 login p-4 mb-3 fadeInTop">
      <div class="card-body">
        <form [formGroup]="formulario" (submit)="iniciar_sesion()">

          <mat-form-field>
            <input matInput type="email" email autocomplete="off" formControlName="username" placeholder="{{ 'login.email' | translate }}">
            <mat-hint align="end">
              <jlps-field-errors [form]="formulario" field="username"></jlps-field-errors>
            </mat-hint>
          </mat-form-field>

          <mat-form-field>
            <input matInput [type]="show_password ? 'text' : 'password'" formControlName="password" placeholder="{{ 'login.password' | translate }}">
            
            <button *ngIf="show_button_password" matTooltip="Mostrar / Ocultar" mat-icon-button type="button" matSuffix (click)="show_password = !show_password">
              <mat-icon class="text-muted">{{show_password ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-hint align="end">
              <jlps-field-errors [form]="formulario" field="password"></jlps-field-errors>
            </mat-hint>
          </mat-form-field>

          <div class="d-md-flex justify-content-between align-items-center mt-4">
            <a class="text-primary font-weight-bold cursor" (click)="open_modal()">
              {{ 'login.singUp' | translate }}
            </a>
            <button mat-flat-button class="rounded-pill px-5 py-1" type="submit" color="accent"> 
              {{ 'login.singIn' | translate }}
            </button>
          </div>
        </form>
        <div class="d-md-flex justify-content-between align-items-center mt-4">
          <small style="text-align: justify;" [innerHTML]="'login.termsAndConditions' | translate">
          </small>
        </div>
      </div>
    </div>
  </div>
</div>
