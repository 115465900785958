import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

// Servicios
import { AppService } from 'src/app/services/settings/app.service';

// Herramientas
import { APP_CONFIG } from 'src/environments/environment.global';

// NgRx
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducers';
import { UI_RESET, FORM_RESET } from 'src/app/store/actions';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private app: AppService,
    private router: Router,
    private store: Store<AppState>,
    private translate: TranslateService
  ) { }

  login(payload: IntLogin) {
    this.app.loading(true);
    this.app.save<any>('/auth/login', payload).subscribe((res: IntLoginResponse) => {
      this.app.loading(false);
      this.router.navigate(['/']);

      this.app.storage.set_item(res.fullName, APP_CONFIG.storage_key_user);
      this.app.storage.set_item(res.userId, APP_CONFIG.storage_key_user_id);
      this.app.storage.set_item(res.roles, APP_CONFIG.storage_key_roles);
      this.app.storage.set_item(res.lang, APP_CONFIG.storage_key_user_lang);

      this.app.token.save({
        token: res.token,
        refresh_token: res.refreshToken
      });

    }, () => this.app.loading(false));
  }

  shared_token(query: any): boolean {
    const shared_token = query.tk;

    if (!!query && !!shared_token) {
      this.app.loading(true);
      this.app.get('/auth/share-token', {
        headers: {
          Authorization: 'Bearer ' + shared_token
        }
      }).subscribe((res: IntLoginResponse) => {
        this.app.loading(false);
        this.router.navigate(['/']);

        this.app.storage.set_item(res.fullName, APP_CONFIG.storage_key_user);
        this.app.storage.set_item(res.userId, APP_CONFIG.storage_key_user_id);
        this.app.storage.set_item(res.roles, APP_CONFIG.storage_key_roles);
        this.app.token.save({
          token: res.token,
          refresh_token: res.refreshToken
        });

        return false;

      }, () => {
        this.router.navigate(['/login'], { queryParams: {} });
        this.app.loading(false);
        return true;
      });
    } else {
      return true;
    }
  }

  refresh_token() {

    const TOKEN_REFRESH = this.app.token.get().refresh_token;
    return this.app.get<any>('/auth/token', {
      headers: {
        Authorization: 'Bearer ' + TOKEN_REFRESH
      }
    }).pipe(
      map(res => {
        this.app.token.save({
          token: res.token,
          refresh_token: res.refreshToken
        });
        return res;
      })
    );
  }

  logout() {
    this.app.storage.clear();
    this.router.navigate(['/login']);
    this.store.dispatch(UI_RESET());
    this.store.dispatch(FORM_RESET());
  }

}

//#region Interfaces

interface IntLogin {
  username: string;
  password: string;
}

interface IntLoginResponse {
  companyId?: number;
  fullName: string;
  roles: string[];
  isActive: boolean;
  refreshToken: string;
  token: string;
  userId: number;
  lang: string
}

//#endregion
