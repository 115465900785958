import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// NgRx
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducers';
import { UI_LOADING } from 'src/app/store/actions';

// Herramientas
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

// Servicios
import { MessageService } from './message.service';
import { Translations } from 'src/app/shared/tools/translations';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  END_POINT = environment.end_point_services;

  constructor(
    public http: HttpClient,
    public message: MessageService,
    private store: Store<AppState>,
    public translate: Translations
  ) { }

  loading(status: boolean) {
    this.store.dispatch(UI_LOADING({ loading: status }));
  }

  save<T>(operation: string, payload: T, edit: boolean = false, options?: any): Observable<T> {
    const METODO = edit ? 'put' : 'post';
    return this.http[METODO](this.END_POINT + operation, payload, { ...options, observe: 'response' }).pipe(
      map((res: any) => this.extract_data(res)),
      catchError(this.handle_error.bind(this))
    );
  }

  get<T>(operation: string, options?: any): Observable<T> {
    return this.http.get(this.END_POINT + operation, { ...options, observe: 'response' }).pipe(
      map((res: any) => this.extract_data(res)),
      catchError(this.handle_error.bind(this))
    );
  }

  delete<T>(operation: string, options?: any): Observable<T> {
    return this.http.delete(this.END_POINT + operation, { ...options, observe: 'response' }).pipe(
      map((res: any) => this.extract_data(res)),
      catchError(this.handle_error.bind(this))
    );
  }

  async confirm_delete<T>(operation: string, options?: any): Promise<T> {
    const CONFIRM = await Swal.fire({
      title: this.translate.getByKey('formLaboratory.delete.title'),
      text: this.translate.getByKey('formLaboratory.delete.text'),
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d32f2f',
      confirmButtonText: this.translate.getByKey('formLaboratory.delete.confirmation')
    });

    if (CONFIRM.value) {
      return this.http.delete(this.END_POINT + operation, { ...options, observe: 'response' }).pipe(
        map((res: any) => this.extract_data(res)),
        catchError(this.handle_error.bind(this))
      ).toPromise();
    }
  }

  private extract_data(response: HttpResponse<any>): any {

    const { headers } = response;

    switch (response.status) {
      case 201:
        var message = this.translate.getByKey('app.savedSuccessfully')
        this.message.success(headers['messageResponse'] || message);  
        break;
      case 202:
      case 204:
        var message = this.translate.getByKey('app.updatedSuccessfully')
        this.message.success(headers['messageResponse'] || message);  
        break;
    }

    return response.body;
  }

  private handle_error(reject: HttpErrorResponse): Error {

    var ERROR_MESSAGE_DEFAULT = ""

    const ERROR_STATUS = reject.status;
    const ERROR_MESSAGE_SERVICE = reject.error ? reject.error.message || reject.error.exception : null;
    const ERROR_MESSAGE = ERROR_MESSAGE_SERVICE || `${reject.name} - ${reject.message}` || ERROR_MESSAGE_DEFAULT;

    switch (ERROR_STATUS) {
      case 400:
      case 404:
      case 405:
      case 415:
      case 422:
        this.message.warning(ERROR_MESSAGE);
        break;

      default:
        const _SHARED_TOKEN = reject.url.match('share-token');
        if (!_SHARED_TOKEN) {
          Swal.fire({
            title: 'UPS..!',
            text: ERROR_MESSAGE,
            type: 'error',
            confirmButtonColor: '#d32f2f',
            allowEscapeKey: false,
            allowOutsideClick: false
          });
        }
        break;
    }

    this.loading(false);

    throw (reject);
  }
}
