import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

// Herramientas
import { IntInfoGeneral } from '../shared/tools/interfaces';

// Servicios
import { AppService } from 'src/app/services/settings/app.service';
import { APP_CONFIG } from 'src/environments/environment.global';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private app: AppService) { }

  get_by_document(params: { document: string }) {
    this.app.loading(true);
    return this.app.get<IntUser>('/user/', { params }).pipe(
      map(res => {
        this.app.loading(false);
        return res;
      })
    );
  }

  get(id: number) {
    this.app.loading(true);
    return this.app.get<IntPatientInfo>(`/user/${id}`).pipe(map(res => {
      this.app.loading(false);
      return res;
    }));
  }

  get_user_medical_record(id: number) {
    this.app.loading(true);
    return this.app.get<IntUserMedicalRecord>(`/user/${id}/medicalRecord`).pipe(map(res => {
      this.app.loading(false);
      return res;
    }));
  }

  update_user_medical_record(id: number, payload: IntUserMedicalRecord) {
    this.app.loading(true);
    return this.app.save<IntUserMedicalRecord>(`/user/${id}/medicalRecord`, payload, true).pipe(map(res => {
      this.app.loading(false);
      return res;
    }));
  }

  get_settings() {
    this.app.loading(true);
    return this.app.get<IntSetting>(`/configuration`).pipe(map(res => {
      this.app.loading(false);
      return res;
    }));
  }

  get_doctor(id: number) {
    this.app.loading(true);
    return this.app.get<IntUserMedic>(`/doctor/${id}`).pipe(map(res => {
      this.app.loading(false);
      return res;
    }));
  }

  save_doctor(payload: IntUserMedic) {

    const EDIT = Boolean(payload.id);
    const URL = EDIT ? `/doctor/${payload.id}` : `/doctor`;

    return this.app.save(URL, payload, EDIT);
  }

}

//#region Interfaces

interface IntUserBasic {
  id: number;
  name: string;
  lastName: string;
  documentType: {
    id?: number;
    name: string;
    description: string;
  };
  documentNumber: string;
  email: string;
}

export interface IntUserMedicalRecord {
  id?: number;
  name?: string;
  age?: string;
  bloodType?: string;
  allergies: {
    id: number;
    description: string;
  }[];
  medicines: {
    id: number,
    description: string,
    frequency: string
  }[];
  sufferings: {
    id: number;
    description: string;
  }[];
}

export interface IntUser extends IntUserBasic {
  birthDate: string;
  emergencyContactName: string;
  emergencyContactNumber: string;
}

export interface IntPatientInfo extends IntUser {
  image?: string;
  gender: {
    id?: number;
    name: string;
    code: string;
  };
  city: IntInfoGeneral;
  healthCompany: IntInfoGeneral;
  prepaidMedicine: IntInfoGeneral;
  bloodType: IntInfoGeneral;
}

export interface IntUserMedic extends IntUserBasic {
  specialties: IntInfoGeneral[];
  education: IntInfoGeneral;
  password?: number;
  professionalCardNumber?: string;
  city: IntInfoGeneral;
  country: IntInfoGeneral;
  type?: IntInfoGeneral;
  startWorkTime?: string;
  endWorkTime?: string;
}

export interface IntSetting {
  doctorType: IntInfoGeneral[];
  bloodTypes: IntInfoGeneral[];
  healthCompanies: IntInfoGeneral[];
  specialties: IntInfoGeneral[];
  countries: IntInfoGeneral[];
  states: IntInfoGeneral[];
  cities: IntInfoGeneral[];
  documentTypes: IntInfoGeneral[];
  prepaidMedicines: IntInfoGeneral[];
  educations: IntInfoGeneral[];
}

//#endregion
