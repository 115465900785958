export class EncryptTools {

  encrypt(payload: any): string {
    const PAYLOAD_STRING = JSON.stringify(payload);
    const PAYLOAD_ENCRYPT = btoa(PAYLOAD_STRING);
    return PAYLOAD_ENCRYPT;
  }

  desencrypt(encrypt: string): any {
    if (encrypt === '' || encrypt.trim() === '') { return false; }
    try {
      const PAYLOAD_JSON = JSON.parse(atob(encrypt));
      return PAYLOAD_JSON;
    } catch (err) {
      return false;
    }
  }
}
