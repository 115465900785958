import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'jlps-control-errors',
  template: `
    <mat-error class="mr-2" *ngIf="control.invalid" [ngSwitch]="get_error()">
    	<span *ngSwitchCase="'required'">{{ 'fieldErrors.obligatoryFiel' | translate }}</span>
    	<span *ngSwitchCase="'min'">{{ 'fieldErrors.biggerNumber' | translate }} {{ get_error_info().min }}.</span>
    	<span *ngSwitchCase="'max'">{{ 'fieldErrors.minorNumber' | translate }} {{ get_error_info().max }}.</span>
    	<span *ngSwitchCase="'pattern'">{{ 'fieldErrors.validEmail' | translate }}</span>
    	<span *ngSwitchCase="'minlength'">{{ 'fieldErrors.minimum' | translate }} {{ get_error_info().requiredLength }} {{ 'fieldErrors.characters' | translate }}</span>
    	<span *ngSwitchCase="'maxlength'">{{ 'fieldErrors.maximum' | translate }} {{ get_error_info().requiredLength }} {{ 'fieldErrors.characters' | translate }}</span>
    	<span *ngSwitchCase="'matDatepickerParse'">'{{ get_error_info().text }}' {{ 'fieldErrors.date' | translate }}</span>
    	<span *ngSwitchCase="'matDatepickerMax'">{{ 'fieldErrors.minorDate' | translate }} '{{ get_error_info().max | date:'dd-MM-yyyy' }}'.</span>
    	<span *ngSwitchCase="'matDatepickerMin'">{{ 'fieldErrors.olderDate' | translate }} '{{ get_error_info().min | date:'dd-MM-yyyy' }}'.</span>
    	<span *ngSwitchDefault>{{ get_error_info().message }}</span>
    </mat-error>`,
  styles: []
})
export class ControlErrorsComponent {

  @Input() control: FormControl;
  @Input() message = 'Ingrese una cuenta de correo valida: usuario@ejemplo.com';

  get_error(): string {
    const ERRORS = this.get_errors();
    const ERROR = Object.keys(ERRORS ? ERRORS : {})[0];
    return ERROR;
  }

  get_error_info(): any {
    const ERRORS = this.get_errors();
    const ERROR = Object.keys(ERRORS)[0];
    return ERRORS[ERROR];
  }

  private get_errors() {
    return this.control && this.control.errors;
  }
}
