
    <div class="loading" *ngIf="loading$ | async">
      <span class="spinner-grow text-primary" role="status"></span>
    </div>
    <div *ngIf="!online" class="p-4 bg-danger text-white d-flex justify-content-center fixed-bottom fadeIn">
    	<div class="container-fluid">
    		<p class="mb-0">
    			<mat-icon class="mr-2">wifi_off</mat-icon>
    			<b>No hay conexión a Internet</b>, los componentes no funcionarán correctamente.
    		</p>
    	</div>
    </div>
    <router-outlet class="fadeIn"></router-outlet>