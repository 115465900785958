import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppService } from 'src/app/services';


// Herramientas
import { FormTools } from '../tools';

@Component({
  selector: 'jlps-field-errors',
  template: `
    <mat-error class="mr-2" *ngIf="formTools.show_has_errors(field)" [ngSwitch]="get_error(field)">
    	<span *ngSwitchCase="'required'">{{ 'fieldErrors.obligatoryFiel' | translate }}</span>
    	<span *ngSwitchCase="'min'">{{ 'fieldErrors.biggerNumber' | translate }} {{ get_error_info(field).min }}.</span>
    	<span *ngSwitchCase="'max'">{{ 'fieldErrors.minorNumber' | translate }} {{ get_error_info(field).max }}.</span>
    	<span *ngSwitchCase="'pattern'">{{ 'fieldErrors.validPatern' | translate }}</span>
    	<span *ngSwitchCase="'minlength'">{{ 'fieldErrors.minimum' | translate }} {{ get_error_info(field).requiredLength }} {{ 'fieldErrors.characters' | translate }}</span>
    	<span *ngSwitchCase="'maxlength'">{{ 'fieldErrors.maximum' | translate }} {{ get_error_info(field).requiredLength }} {{ 'fieldErrors.characters' | translate }}</span>
    	<span *ngSwitchCase="'matDatepickerParse'">'{{ get_error_info(field).text }}' {{ 'fieldErrors.date' | translate }}</span>
    	<span *ngSwitchCase="'matDatepickerMax'">{{ 'fieldErrors.minorDate' | translate }} '{{ get_error_info(field).max | date:'dd-MM-yyyy' }}'.</span>
    	<span *ngSwitchCase="'matDatepickerMin'">{{ 'fieldErrors.olderDate' | translate }} '{{ get_error_info(field).min | date:'dd-MM-yyyy' }}'.</span>
    	<span *ngSwitchDefault>{{ get_error_info(field).message }}</span>
    </mat-error>`,
  styles: []
})
export class FieldErrorsComponent implements OnInit {

  @Input() form: FormGroup;

  @Input() field: any;

  formTools: FormTools;

  ngOnInit() {
    this.formTools = new FormTools(this.form);
    
  }

  get_error(field: string): string {
    return this.formTools.get_name_error(field);
  }

  get_error_info(field: string): any {
    const ERROR = this.formTools.get_error(field);
    return ERROR;
  }
}
