import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Modulos
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

// Material
import * as Material from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

// Configuraciones, Componentes, Pipes, Directivas, etc...
import * as Config from 'src/environments/environment.global';
import * as Component from 'src/app/shared/components';
import * as Modal from 'src/app/shared/components/modals';
import * as Form from 'src/app/shared/components/forms';
import * as Pipe from 'src/app/shared/pipes';

import {TranslateModule, TranslateService} from '@ngx-translate/core';

const MATERIAL = [
  Material.MatButtonModule,
  Material.MatCheckboxModule,
  Material.MatDatepickerModule,
  Material.MatFormFieldModule,
  Material.MatExpansionModule,
  Material.MatIconModule,
  Material.MatInputModule,
  Material.MatListModule,
  Material.MatPaginatorModule,
  Material.MatSelectModule,
  Material.MatSidenavModule,
  Material.MatSortModule,
  Material.MatTableModule,
  Material.MatToolbarModule,
  Material.MatTooltipModule
];

const MODULES = [
  FormsModule,
  NgbModule,
  ReactiveFormsModule,
  MATERIAL
];

const MODALS = [
  Modal.ModalSearchPatientComponent
];

const DECLARACIONES = [
  Pipe.ImgPipe,
  Pipe.DomseguroPipe,
  Pipe.MomentFormatPipe,
  Component.ContainerComponent,
  Component.ControlErrorsComponent,
  Component.FieldErrorsComponent,
  Component.NavbarComponent,
  Component.SidebarComponent,
  Component.SidebarUserComponent,
  Component.ListItemFormComponent,
  Component.IconListItemFormComponent,
  Form.FormSearchPatientComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MODULES,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(Config.TOASTR_CONFIG),
    NgxCurrencyModule.forRoot(Config.MASK_CONFIG),
    TranslateModule
  ],
  providers: [
    { provide: Material.MAT_DATE_LOCALE, useValue: 'es' },
    { provide: Material.MAT_DATE_FORMATS, useValue: Config.DATE_FORMAT },
    { provide: Material.DateAdapter, useClass: MomentDateAdapter, deps: [Material.MAT_DATE_LOCALE] },
    { provide: Material.MatPaginatorIntl, useValue: Config.paginator_esp() }
  ],
  declarations: [
    DECLARACIONES,
    MODALS
  ],
  exports: [
    ToastrModule,
    NgxMaskModule,
    NgxCurrencyModule,
    TranslateModule,
    MODULES,
    MODALS,
    DECLARACIONES
  ],
  entryComponents: MODALS
})
export class SharedModule { }
