import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modulos
import { SharedModule } from 'src/app/shared/shared.module';

// Rutas
import { RouterModule } from '@angular/router';
import { CoreRoutes } from './core.routes';

// Componentes
import { ShellComponent } from './shell.component';

// Páginas
import * as Page from './pages';
import * as Modal from './components/modals';

const MODALS = [Modal.ModalNewAccountComponent];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forRoot(CoreRoutes, { useHash: true, scrollPositionRestoration: 'top', scrollOffset: [0, 0] })
  ],
  declarations: [
    ShellComponent,
    Page.LoginComponent,
    ...MODALS
  ],
  exports: [ShellComponent],
  entryComponents: MODALS
})
export class CoreModule { }
