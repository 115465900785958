<div class="p-md-4">
  <div class="modal-header border-0">
    <div class="modal-title">
      <h4 class="text-primary">{{ 'modalSearch.patient' | translate }}</h4>
      <p>{{ 'modalSearch.description' | translate }}</p>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <jlps-form-search-patient (response)="modal.close()"></jlps-form-search-patient>
  </div>
</div>
